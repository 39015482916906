import { inject } from 'inversify-props'
import { UserServiceInterface } from './user.service.interface'
import { BaseCrudService } from '../baseCrud.service'
import { gql } from '@apollo/client/core'
import { AxiosService } from '../axios/axios.service'
import {
  ArcDto,
  CreateUserStatusDto, EntityDeletionSummaryDto,
  IdArgs,
  ReportedUserGetArgs,
  ReportedUserModelDto,
  UpdateUserDto,
  UpdateUserPreferencesDto,
  UpdateUserStatusDto,
  UserDto,
  UserReviewerStatsDto,
  UserStatsDto, UserStatusDto,
  UserStatusGetArgs
} from 'booksprout'
import { StoreService } from '../store.service'
import { BaseRootState } from '../../../store/baseRootState'
import { ConfigService } from '../config.service'
import { ArcServiceInterface } from '../../arcs/arc.service.interface'

export class UserService extends BaseCrudService<UserDto> implements UserServiceInterface<UserDto> {
  public apiControllerPath = 'user'

  public constructor (
    @inject('AxiosService') readonly axiosService: AxiosService,
    @inject('ArcService') private readonly arcService: ArcServiceInterface<ArcDto>,
    @inject('StoreService') storeService: StoreService<BaseRootState>,
    @inject('ConfigService') configService: ConfigService
  ) {
    super(configService, storeService, axiosService)
  }

  /**
   * Our common props we want to return with the user objecy from the server.
   */
  public readonly fragments = {
    userStatus: gql`
      fragment UserStatusFragment on UserStatusDto {
        id,
        active,
        additionalInformation,
        adminUser {
          id,
          emailAddress,
          name
        },
        adminUserId,
        createdDate,
        expiryDate,
        isRestriction,
        type,
        userId
      }
    `,
    userProfile: gql`
      fragment UserProfileFragment on UserDto {
        id,
        createdDate,
        reviewerRole,
        authorRole,
        emailAddress,
        name,
        amazonProfileLink,
        consentMethod,
        kindleEmail,
        verifiedKindleEmail,
        image,
        termsConsentRevokedDate,
        topReviewerAppStatus,
        topReviewerAppStatusDate,
        topReviewerAppStatusReason,
        stripeCustomerId,
        isAdmin,
        isLocked,
        forceLoyaltyDiscountView,
        canActAs {
          id,
          emailAddress
        },
        userEmails {
          id,
          emailAddress,
          isDefault,
          status,
          postmarkBounceCode,
          postmarkBounceName,
          postmarkBounceDescription,
          postmarkBounceDate,
          postmarkBounceCount
        },
        preferences {
          alertNewArc,
          alertReviewDue,
          alertReviewDueIn2Days,
          dailyUpdatesOnFollowCount,
          penNameLimit,
          reminderBookLinks,
          reviewSitesFlag,
          alertTimeToPublish,
          alertReadyToReview,
          alertFinalFollowup,
          alertDelayExpiring,
          alertDelayExpired,
          alertNewArcParticipant
          alertNewReview,
          alertArcCancellation,
          alertReviewRejected,
          alertNewExternalReview,
          alertNewTeamApplicant
          emailOnMessage,
          invitedToTeam
        },
        status,
        termsConsentDate,
        following {
          id
        },
        hasActingAs,
        completedTours,
        countryCode,
        usedCouponCodes
      }
    `
  }

  /**
   * Make our User object based on the API response. This will convert the preference key / value pairs
   * into our User Preference object.
   * @param user
   */
  public makeUserResult (user: any): UserDto {
    const result = {
      ...user,
      name: user.name?.trim(),
      termsConsentDate: user.termsConsentDate
        ? new Date(user.termsConsentDate)
        : void 0,
      termsConsentRevokedDate: user.termsConsentRevokedDate
        ? new Date(user.termsConsentRevokedDate)
        : void 0,
      topReviewerAppStatusDate: user.topReviewerAppStatusDate
        ? new Date(user.topReviewerAppStatusDate)
        : void 0
    }

    return result
  }

  /**
   * Strip out preferences which we know the server won't update i.e penNameLimit
   * If we don't do this, an error will show saying it can't update that value
   * This is for safety. We don't want users adding props manually and updating their prefs.
   * @param preferences
   */
  private sanitisePreferences (preferences: UpdateUserPreferencesDto | undefined): UpdateUserPreferencesDto | undefined {
    if (preferences === void 0) return void 0

    const {
      // @ts-ignore
      penNameLimit,
      // @ts-ignore
      __typename,
      ...result
    } = preferences

    return result
  }

  public getUsersToMimic (needle: string): Promise<UserDto[]> {
    return this.apolloClientService.query({
      variables: {
        needle
      },
      query: gql`
        query GetUsersToMimic ($needle: String!) {
          getUsersToMimic (needle: $needle) {
            id,
            emailAddress,
            name,
            authorRole,
            reviewerRole,
            image
          }
        }
      `
    }).then((r: any) => {
      return r.map(this.makeUserResult)
    })
  }

  /**
   * Shouldn't be used. Auth service will handle
   * @param model
   */
  public create (model: UserDto): Promise<UserDto> {
    throw new Error('Unauthorised')
  }

  /**
   * Will only work for admin users (API limitation)
   * @param id
   */
  public checkDelete (id: number): Promise<EntityDeletionSummaryDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query CheckDeleteUser ($id: Int!) {
          checkDeleteUser (id: $id) {
            count,
            dtoTypeName,
            ids
          }
        }
      `
    })
  }

  /**
   * Will only work for admin users (API limitation)
   * @param id
   */
  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation ConfirmDeleteUser (
          $id: Int!
        ) {
          confirmDeleteUser(id: $id) {
            id
          }
        }
      `
    })
  }

  /**
   * Shouldn't be used. Auth service will handle
   * @param model
   */
  public get (params?: any): Promise<UserDto[]> {
    throw new Error('Unauthorised')
  }

  /**
   * Shouldn't be used. Auth service will handle
   * @param model
   */
  public getById (id: number, args?: any): Promise<UserDto | null> {
    if (args?.mimicUser) {
      return this.apolloClientService.query({
        query: gql`
          query GetMimicUserProfile {
            getMimicUserProfile {
              ...UserProfileFragment
            }
          }
          ${this.fragments.userProfile}
        `
      }).then((r: any) => {
        return this.makeUserResult(r)
      })
    } else {
      return this.apolloClientService.query({
        query: gql`
          query GetUserProfile {
            getUserProfile {
              ...UserProfileFragment
            }
          }
          ${this.fragments.userProfile}
        `
      }).then((r: any) => {
        return this.makeUserResult(r)
      })
    }
  }

  /**
   * Profile updates ONLY. Won't work for password changes.
   * @param model
   */
  public update (model: UpdateUserDto): Promise<UserDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: model.id,
        amazonProfileLink: model.amazonProfileLink,
        image: model.image,
        kindleEmail: model.kindleEmail,
        verifiedKindleEmail: model.verifiedKindleEmail,
        name: model.name,
        preferences: this.sanitisePreferences(model.preferences),
        notificationRegistrationToken: model.notificationRegistrationToken,
        completedTours: model.completedTours,
        authorRole: model.authorRole
      },
      mutation: gql`
        mutation UpdateUserProfile (
          $id: Int!,
          $amazonProfileLink: String,
          $image: String,
          $kindleEmail: String,
          $verifiedKindleEmail: Boolean,
          $name: String,
          $preferences: UpdateUserPreferencesDto,
          $notificationRegistrationToken: String,
          $completedTours: String
          $authorRole: String
        ) {
          updateUserProfile (
            id: $id,
            amazonProfileLink: $amazonProfileLink,
            image: $image,
            kindleEmail: $kindleEmail,
            verifiedKindleEmail: $verifiedKindleEmail,
            name: $name,
            preferences: $preferences,
            notificationRegistrationToken: $notificationRegistrationToken,
            completedTours: $completedTours
            authorRole: $authorRole
          ) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    }).then((r: any) => {
      return this.makeUserResult(r)
    })
  }

  public addNewEmailAddress (emailAddress: string): Promise<UserDto> {
    return this.apolloClientService.mutate({
      variables: {
        emailAddress
      },
      mutation: gql`
        mutation AddNewEmailAddress (
          $emailAddress: String!
        ) {
          addNewEmailAddress (emailAddress: $emailAddress) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    }).then((r: any) => {
      return this.makeUserResult(r)
    })
  }

  public removeEmailAddress (emailAddress: string): Promise<UserDto> {
    return this.apolloClientService.mutate({
      variables: {
        emailAddress
      },
      mutation: gql`
        mutation RemoveEmailAddress (
          $emailAddress: String!
        ) {
          removeEmailAddress (emailAddress: $emailAddress) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    }).then((r: any) => {
      return this.makeUserResult(r)
    })
  }

  public makeDefaultEmailAddress (emailAddress: string): Promise<UserDto> {
    return this.apolloClientService.mutate({
      variables: {
        emailAddress
      },
      mutation: gql`
        mutation SetDefaultEmailAddress (
          $emailAddress: String!
        ) {
          setDefaultEmailAddress (emailAddress: $emailAddress) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    }).then((r: any) => {
      return this.makeUserResult(r)
    })
  }

  public withdrawContent (): Promise<any> {
    return this.axiosService.axios.post('user/withdraw-consent')
  }

  public reinstateContent (): Promise<any> {
    return this.axiosService.axios.post('user/reinstate-consent')
  }

  consentGiven (): Promise<any> {
    return this.axiosService.axios.post('user/consent-given')
  }

  public addVirtualAssistant (emailAddress: string): Promise<UserDto> {
    return this.apolloClientService.mutate({
      variables: {
        emailAddress
      },
      mutation: gql`
        mutation AddVirtualAssistant (
          $emailAddress: String!
        ) {
          addVirtualAssistant (emailAddress: $emailAddress) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    }).then((r: any) => {
      return this.makeUserResult(r)
    })
  }

  public removeVirtualAssistant (emailAddress: string): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        emailAddress
      },
      mutation: gql`
        mutation RemoveVirtualAssistant (
          $emailAddress: String!
        ) {
          removeVirtualAssistant (emailAddress: $emailAddress) {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    })
  }

  public addAuthorRoleToUser (): Promise<UserDto> {
    return this.apolloClientService.mutate({
      mutation: gql`
        mutation AddAuthorRoleToUser {
          addAuthorRoleToUser {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    })
  }

  getUserStats (): Promise<UserStatsDto> {
    return this.apolloClientService.query({
      query: gql`
        query GetUserStats {
          getUserStats {
            completeArcCount,
            newReviewCount,
            pendingTeamMembers,
            arcCount,
            bookCount,
            penNameCount,
            arcTeamCount,
            reviewsCount,
            anyTeamHasMember,
            anyArcHasParticipant,
            anyArcHasParticipant,
            anyPenNameHasFollower,
            hasReviewOlderThanOneMonth,
            hasHadTeamMemberForOneMonth
          }
        }
      `
    })
  }

  getReviewerStats (args: IdArgs): Promise<UserReviewerStatsDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetReviewerStats (
          $id: Int!
        ) {
          getReviewerStats (
            id: $id
          ) {
            arcsDownloadedThisWeek,
            earliestNextClaimDate,
            arcTotalOverdueDays,
            onTimeReviewRateStats {
              allClaimsCount,
              lateCount,
              onTimeReviewRate,
              pcOfLateClaims
            },
            isTrustedReviewer,
            totalReviewCount,
            averageRating,
            cancelledReviewCount,
            dueReviewCount,
            activeArcCount
          }
        }
      `
    })
  }

  applyForTopReviewer (): Promise<UserDto> {
    return this.apolloClientService.mutate({
      mutation: gql`
        mutation ApplyForTopReviewerRole {
          applyForTopReviewerRole {
            ...UserProfileFragment
          }
        }
        ${this.fragments.userProfile}
      `
    })
  }

  addUserStatus (args: CreateUserStatusDto): Promise<UserStatusDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation AddUserStatus (
          $userId: Int!,
          $type: Int!,
          $active: Boolean!,
          $isRestriction: Boolean!,
          $additionalInformation: String,
          $expiryDate: DateTime
        ) {
          addUserStatus (
            userId: $userId,
            type: $type,
            active: $active,
            isRestriction: $isRestriction,
            expiryDate: $expiryDate,
            additionalInformation: $additionalInformation
          ) {
            ...UserStatusFragment
          }
        }
        ${this.fragments.userStatus}
      `
    })
  }

  updateUserStatus (args: UpdateUserStatusDto): Promise<UserStatusDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation UpdateUserStatus (
          $id: Int!,
          $type: Int,
          $active: Boolean,
          $isRestriction: Boolean,
          $additionalInformation: String,
          $expiryDate: DateTime
        ) {
          updateUserStatus (
            id: $id,
            type: $type,
            active: $active,
            isRestriction: $isRestriction,
            expiryDate: $expiryDate,
            additionalInformation: $additionalInformation
          ) {
            ...UserStatusFragment
          }
        }
        ${this.fragments.userStatus}
      `
    })
  }

  getUserStatuses (args: UserStatusGetArgs): Promise<UserStatusDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetUserStatuses (
          $active: Boolean,
          $type: Int,
          $adminUserId: Int,
          $needle: String,
          $isRestriction: Boolean,
          $userId: Int!
        ) {
          getUserStatuses (
            userId: $userId,
            active: $active,
            type: $type,
            adminUserId: $adminUserId,
            needle: $needle,
            isRestriction: $isRestriction
          ) {
            ...UserStatusFragment
          }
        }
        ${this.fragments.userStatus}
      `
    })
  }

  getUserReports (args: ReportedUserGetArgs): Promise<ReportedUserModelDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetUserReports (
          $userId: Int!,
          $sinceLastRestriction: Boolean,
          $ignored: Boolean,
          $system: Boolean
        ) {
          getUserReports (
            userId: $userId,
            sinceLastRestriction: $sinceLastRestriction,
            ignored: $ignored,
            system: $system
          ) {
            date,
            extendedReason,
            reasonType,
            reportedByUser {
              id,
              name,
              emailAddress,
              image
            },
            reportedDocumentId,
            type,
            ignored,
            claim {
              ...ArcReviewClaimFragment
            }
          }
        },
        ${this.arcService.arcReviewClaimFragments.reviewClaim}
      `
    })
  }

  addLeadToCampaign (ref: string): Promise<boolean> {
    if (ref) {
      return this.axiosService.axios.post('first-promoter/add-lead', {
        ref
      })
    }

    return Promise.resolve(false)
  }
}

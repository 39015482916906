import { GetterTree } from 'vuex'
import { SystemState } from './types'
import { BaseRootState } from '../../baseRootState'

export const getters: GetterTree<SystemState, BaseRootState> = {
  getReturnLocation (state) {
    return state.returnLocation
  },
  getReturnCrudModel (state) {
    return state.returnCrudModel
  },
  getReturnLabel (state) {
    return state.returnLabel
  },
  getCompleteLabel (state) {
    return state.completeLabel
  },
  getAttachModelId (state) {
    return state.attachModelId
  },
  getMeta (state) {
    return state.meta
  }
}

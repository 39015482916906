import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
// Components
import { QAvatar, QField, QIcon, QImg, QInnerLoading, QSpinnerGears, QUploader } from 'quasar'
import { BsBtn } from '../index'
import { validStr } from 'booksprout'
import { BaseBsComponentMixin, ComponentState } from '../mixins/common'
import { SHOW_MESSAGE_DIALOG } from '../../../store/actions/ui'

// Mixins
// @ts-ignore
import ClickEvent = JQuery.ClickEvent

const Papa = require('papaparse')

@Component
export default class BsUpload extends BaseBsComponentMixin {
  @Prop({ type: String }) readonly value!: string
  @Prop({ type: Boolean, default: true }) readonly canReset!: boolean
  @Prop({ type: Boolean, default: true }) readonly autoUpload!: boolean
  @Prop({ type: String }) readonly type: 'img' | 'input' | 'file' | undefined
  @Prop({ type: String }) readonly label: string | undefined
  @Prop({ type: Number }) readonly width: number | undefined
  @Prop({ type: Number }) readonly height: number | undefined
  @Prop({ type: Boolean }) readonly square: boolean | undefined
  @Prop({ type: Boolean }) readonly hidePreview: boolean | undefined
  @Prop({ type: String }) readonly hintText!: string
  @Prop({ type: Boolean }) readonly parseCsv!: boolean
  @Prop({ type: Boolean }) readonly csvNoHeader!: boolean

  hasFile = false
  forceAllowDragAndDrop = false
  fileName = ''
  isUploading = false
  uploaderHtml = `
    <div><strong>Drag & Drop</strong> <br /> ${this.hintText || 'a image file here'}</div>
    <strong>OR</strong>
  `

  get validValue () {
    return this.value !== '' && this.value !== void 0 && this.value !== null
  }

  isComponentIsValid (val?: any) {
    return (val !== '' && val !== void 0 && val !== null) || (this.autoUpload === false && validStr(this.fileName))
  }

  reset () {
    const uploader = (this.$refs.bsUpload as QUploader)
    uploader.reset()
    uploader.abort()
    this.fileName = ''
    this.hasFile = false
    this.isUploading = false
    this.$emit('input', '')
  }

  __filesAdded (files: any) {
    this.isUploading = true
    this.$emit('begin-processing', files)
    if (files.length) {
      const
        fileReader = new FileReader(),
        file = files[0]

      this.hasFile = this.autoUpload !== true || this.type === 'input'
      this.fileName = file.name

      if (file.size > 15 * 1024 * 1024) {
        this.$q.loading.hide()
        this.$emit('error-processing', 'file_size')
      } else {
        // Get the image the user has uploaded as a base64 encoded string and emit it (return the value)
        fileReader.onload = (fileLoadedEvent: any) => {
          this.$emit('done-processing', files)
          // this.isUploading = false
          if (this.type !== 'input') {
            const srcData = fileLoadedEvent.target.result

            if (this.parseCsv) {
              this.$emit('parsed', Papa.parse(srcData, {
                header: !this.csvNoHeader,
                skipEmptyLines: true
              }))
            }
            this.$emit('input', srcData)
          }
        }

        if (this.type !== 'input' && !this.parseCsv) {
          fileReader.readAsDataURL(file)
        } else {
          fileReader.readAsText(file)
        }
      }
    }
    this.$emit('done-processing', files)
  }

  __renderInputUpload (h: CreateElement) {
    return [
      h(QIcon, {
        props: {
          name: this.hasFile ? 'icon-check' : 'icon-options'
        }
      }),
      h('div', {
        staticClass: 'bs-upload__file col',
        on: {
          click: () => (this.$refs.bsUpload as QUploader).pickFiles()
        }
      }, [
        this.fileName || this.$attrs.placeholder,
        this.__renderDeleteFileButton(h)
      ]),
      h(BsBtn, {
        staticClass: 'icon-bs-g',
        props: {
          icon: 'app:browse',
          iconOnly: true
        },
        on: {
          click: () => (this.$refs.bsUpload as QUploader).pickFiles()
        }
      })
    ]
  }

  __renderDeleteFileButton (h: CreateElement) {
    if (this.hasFile === false) return void 0

    return h(QIcon, {
      staticClass: 'on-right',
      props: {
        name: 'cancel'
      },
      on: {
        click: (e: ClickEvent) => {
          e.preventDefault()
          e.stopPropagation()
          this.reset()
          this.$emit('remove')
        }
      }
    })
  }

  __renderPreview (h: CreateElement) {
    if (this.hidePreview) {
      return
    }

    return h('div', {
      staticClass: 'bs-upload__before',
      class: [{
        'bs-upload__before--waiting': !this.hasFile,
        'bs-upload__before--has-file': this.hasFile,
        'bs-upload__before--has-image': this.validValue && !this.hasFile
      }]
    }, [
      h(QAvatar, {
        style: {
          height: this.square ? this.height + 'px' : void 0,
          width: this.square ? this.width + 'px' : void 0
        },
        props: {
          square: this.type === 'img' || this.square
        }
      }, [
        h(QImg, {
          props: {
            src: this.value || '/cdn/awaiting-image.png'
          }
        })
      ])
    ])
  }

  __renderDragAndDropUpload (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-upload__drag-drop-container'
    }, [
      h('div', {
        staticClass: 'bs-upload__hint',
        domProps: {
          innerHTML: this.type === 'img' ? void 0 : this.uploaderHtml
        }
      }),
      h(BsBtn, {
        props: {
          secondary: true,
          label: this.label || 'Browse',
        },
        on: {
          click: () => (this.$refs.bsUpload as QUploader).pickFiles()
        }
      })
    ])
  }

  __renderResetFile (h: CreateElement) {
    if (!this.canReset) {
      return void 0
    }

    let label = ''
    if (this.type !== 'img') {
      label = 'Remove Image'
    }

    if (this.type === 'file') {
      label = 'Remove File'
    }

    return h('div', {
      staticClass: 'bs-upload__existing-container'
    }, [
      h(BsBtn, {
        props: {
          secondary: true,
          label: label
        },
        on: {
          click: () => {
            this.reset()
            this.$emit('remove')
          }
        }
      }),
      this.type !== 'img' ? void 0 : h(BsBtn, {
        props: {
          flat: true,
          icon: 'icon-check'
        },
        on: {
          click: () => {
            this.$emit('done', this.value)
            this.hasFile = false
          }
        }
      })
    ])
  }

  __renderScopedSlots (h: CreateElement) {
    const node: any[] = []

    if (this.type === 'input') {
      node.push(this.__renderInputUpload(h))
    } else {
      node.push(this.__renderPreview(h))
      if (this.type !== 'img' || !this.validValue || this.hasFile) {
        if (!this.forceAllowDragAndDrop && (this.hasFile || this.validValue)) { // We have a temp file we can upload
          node.push(this.__renderResetFile(h))
        } else if (this.hasFile === false && (this.autoUpload === void 0 || !this.validValue || this.forceAllowDragAndDrop)) {
          node.push(this.__renderDragAndDropUpload(h))
        }
      }
    }

    const slots = {
      list: () => {
        return h('div', {
          staticClass: 'bs-upload__content'
        }, [node])
      }
    }

    return slots
  }

  upload () {
    return (this.$refs.bsUpload as QUploader).upload()
  }

  __renderProgress (h: CreateElement) {
    return h(QInnerLoading, {
      props: {
        showing: this.isUploading
      }
    }, [
      h(QSpinnerGears, {
        props: {
          color: 'primary',
          size: '30px'
        }
      })
    ])
  }

  render (h: CreateElement) {
    // If  value has been passed in, set it.
    if (this.value) {
      this.hasFile = this.type === 'input'
      this.fileName = this.value
    }

    const props = {
      flat: true,
      noThumbnails: true,
      color: 'transparent',
      multiple: false,
      autoUpload: this.autoUpload
    }

    const scopedSlots = {
      ...this.__renderScopedSlots(h)
    }

    const listeners = {
      added: this.__filesAdded,
      'factory-failed': (errorCode: string) => {
        const uploader = (this.$refs.bsUpload as QUploader)
        uploader.reset()
        uploader.abort()
        this.fileName = ''
        this.hasFile = false
        this.isUploading = false
        this.forceAllowDragAndDrop = true

        //this.hasFile = false
        this.componentState = ComponentState.Invalid
        this.errorMessage = this.$tc('system.errors.' + errorCode)
      },
      failed: (info: any) => {
        this.isUploading = false
        let response

        try {
          response = JSON.parse(info.xhr.response)
        } catch (e) {
          console.error('failed to parse json', e, info.xhr.response)
          response = JSON.parse('{}')
        }

        const message = response.message?.errorCode
          ? this.$tc('system.errors.' + response.message.errorCode)
          : response.message || this.$tc(`system.errors.${this.type === 'file' ? 'fileUploadFailed' : 'imageUploadFailed'}`)

        this.$emit('failed', message)

        void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
          error: true,
          message
        })

        this.reset()
      },
      uploaded: (data: any) => {
        this.$emit('uploaded', data)
      },
      finish: () => {
        this.isUploading = false
        this.$emit('finish')
      }
    }

    const
      isInput = this.type === 'input',
      isImg = this.type === 'img',
      isFile = this.type === 'file'

    return h('div', {
      staticClass: 'bs-upload',
      style: {
        height: isImg || isFile ? this.height + 'px' : void 0,
        width: isImg || isFile ? this.width + 'px' : void 0
      },
      attrs: {
        // @ts-ignore
        ['data-model']: this.$vnode.data.model.expression
      },
      class: this.getClasses({
        'bs-upload--input': isInput,
        'bs-upload--img': isImg,
        'bs-upload--inline': !isInput && !isImg,
        'bs-upload--no-file': !this.hasFile,
        'bs-upload--has-file': this.hasFile,
        'bs-upload--square': this.square,
        'bs-upload--is-uploading': this.isUploading
      })
    }, [
      h(QField, {
        staticClass: 'bs-upload-q-field full-width',
        props: this.getValidationProps({
          borderless: true,
          bottomSlots: true,
          noErrorIcon: true,
          dense: true,
          stackLabel: true
        }),
        scopedSlots: {
          error: () => this.__renderErrorSlot(h),
          ...this.__renderAutoSaveMessage(h)
        }
      }, [
        h(QUploader, {
          staticClass: 'full-width',
          ref: 'bsUpload',
          props: this.getRenderProps(props),
          on: this.getListeners(listeners),
          attrs: this.getAttributes(),
          scopedSlots: this.getScopedSlots(h, scopedSlots)
        }),
        this.isUploading ? this.__renderProgress(h) : void 0
      ])
    ])
  }
}

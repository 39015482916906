import { gql } from '@apollo/client/core'

import { AxiosService, BaseCrudService, ConfigService, inject, StoreService } from 'booksprout-app'
import { ArcTeamServiceInterface } from './arcTeam.service.interface'
import {
  ArcTeamDto,
  ArcTeamGetArgs, ArcTeamGetByIdArgs, ArcTeamMemberStatusCounts, ArcTeamPagedResultDto, bsConstants,
  CreateArcTeamDto, EntityDeletionSummaryDto,
  UpdateArcTeamDto
} from 'booksprout'

export class ArcTeamService extends BaseCrudService<ArcTeamDto> implements ArcTeamServiceInterface<ArcTeamDto> {
  public apiControllerPath = 'arcTeam'

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') axiosService: AxiosService
  ) {
    super(configService, storeService, axiosService)
  }

  private readonly arcTeamFragments = {
    arcTeam: gql`
      fragment ArcTeamFragment on ArcTeamDto {
        id,
        name,
        description,
        image,
        defaultDisclaimer,
        applicantCount,
        autoActionEmailAddresses {
          emailAddress,
          type
        },
        arcTeamMembers {
          id,
          status,
          statusDate,
          reviewerStatus,
          type,
          userId
        },
        isDraft,
        followerCount,
        isActivePublisherTeam,
        userIsFollowing,
        averageRating,
        reviewCount
      }
    `
  }

  public async get (params?: ArcTeamGetArgs): Promise<ArcTeamPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetArcTeams (
          $genericSearchTerm: String,
          $genericSearch: Boolean,
          $teamMemberId: Int,
          $skip: Int,
          $take: Int,
          $sortBy: String
          $status: Int
          $excludeTeamsUserIsAMemberOf: Int
          $forUserId: Int
        ) {
          arcTeams (
            genericSearchTerm: $genericSearchTerm,
            genericSearch: $genericSearch,
            teamMemberId: $teamMemberId,
            skip: $skip,
            take: $take,
            sortBy: $sortBy
            status: $status
            excludeTeamsUserIsAMemberOf: $excludeTeamsUserIsAMemberOf
            forUserId: $forUserId
          ) {
            totalRows,
            items {
              ...ArcTeamFragment
            }
          }
        }
        ${this.arcTeamFragments.arcTeam}
      `
    }).then((result: any) => {
      return {
        items: result.items,
        totalRows: result.totalRows
      }
    })
  }

  public getById (id: number, args?: ArcTeamGetByIdArgs): Promise<ArcTeamDto> {
    return this.apolloClientService.query({
      variables: {
        id,
        ...args
      },
      query: gql`
        query GetArcTeam (
          $id: Int!,
          $genericSearch: Boolean,
          $getAverageRating: Boolean
        ) {
          arcTeam (
            id: $id,
            genericSearch: $genericSearch,
            getAverageRating: $getAverageRating
          ) {
            ...ArcTeamFragment
          }
        }
        ${this.arcTeamFragments.arcTeam}
      `
    })
  }

  public create (model: CreateArcTeamDto): Promise<ArcTeamDto> {
    return this.apolloClientService.mutate({
      variables: {
        name: model.name,
        description: model.description,
        image: model.image,
        defaultDisclaimer: model.defaultDisclaimer,
        autoApproveEmailAddresses: model.autoApproveEmailAddresses,
        autoVipEmailAddresses: model.autoVipEmailAddresses,
        autoBlockEmailAddresses: model.autoBlockEmailAddresses
      },
      mutation: gql`
        mutation CreateArcTeam (
          $name: String!,
          $description: String,
          $image: String,
          $defaultDisclaimer: Int,
          $autoApproveEmailAddresses: [String!],
          $autoVipEmailAddresses: [String!],
          $autoBlockEmailAddresses: [String!]
        ) {
          createArcTeam (
            name: $name,
            description: $description,
            image: $image,
            defaultDisclaimer: $defaultDisclaimer,
            autoApproveEmailAddresses: $autoApproveEmailAddresses,
            autoVipEmailAddresses: $autoVipEmailAddresses,
            autoBlockEmailAddresses: $autoBlockEmailAddresses
          ) {
            ...ArcTeamFragment
          }
        }
        ${this.arcTeamFragments.arcTeam}
      `
    })
  }

  public update (model: UpdateArcTeamDto): Promise<ArcTeamDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: model.id,
        name: model.name,
        description: model.description,
        image: model.image,
        defaultDisclaimer: model.defaultDisclaimer,
        autoApproveEmailAddresses: model.autoApproveEmailAddresses,
        autoVipEmailAddresses: model.autoVipEmailAddresses,
        autoBlockEmailAddresses: model.autoBlockEmailAddresses
      },
      mutation: gql`
        mutation UpdateArcTeam (
          $id: Int!,
          $name: String,
          $description: String,
          $image: String,
          $defaultDisclaimer: Int,
          $autoApproveEmailAddresses: [String!],
          $autoVipEmailAddresses: [String!],
          $autoBlockEmailAddresses: [String!]
        ) {
          updateArcTeam (
            id: $id,
            name: $name,
            description: $description,
            image: $image,
            defaultDisclaimer: $defaultDisclaimer,
            autoApproveEmailAddresses: $autoApproveEmailAddresses,
            autoVipEmailAddresses: $autoVipEmailAddresses,
            autoBlockEmailAddresses: $autoBlockEmailAddresses
          ) {
            ...ArcTeamFragment
          }
        }
        ${this.arcTeamFragments.arcTeam}
      `
    })
  }

  public checkDelete (id: number): Promise<EntityDeletionSummaryDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query CheckDeleteArcTeam ($id: Int!) {
          checkDeleteArcTeam (id: $id) {
            count,
            dtoTypeName,
            ids
          }
        }
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation ConfirmDeleteArcTeam (
          $id: Int!
        ) {
          confirmDeleteArcTeam(id: $id) {
            id
          }
        }
      `
    })
  }

  countFollowers (arcTeam: ArcTeamDto | undefined): number {
    return this.countMembers(arcTeam) + (arcTeam?.followerCount || 0)
  }

  countMembers (arcTeam: ArcTeamDto | undefined): number {
    if (!arcTeam || !arcTeam?.arcTeamMembers || arcTeam.arcTeamMembers.length === 0) {
      return 0
    }

    return arcTeam.arcTeamMembers.filter(f => [
      bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE
    ].includes(f.status)).length
  }

  follow (teamId?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id: teamId
      },
      mutation: gql`
        mutation FollowTeam (
          $id: Int!
        ) {
          followTeam (id: $id) {
            id
          }
        }
      `
    })
  }

  unfollow (teamId?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id: teamId
      },
      mutation: gql`
        mutation UnfollowTeam (
          $id: Int!
        ) {
          unfollowTeam (id: $id) {
            id
          }
        }
      `
    })
  }

  async getTeamMemberStatusCounts (args: ArcTeamGetByIdArgs): Promise<ArcTeamMemberStatusCounts> {
    return this.apolloClientService.query({
      variables: {
        id: args.id
      },
      query: gql`
        query GetTeamMemberStatusCounts ($id: Int!) {
          getTeamMemberStatusCounts (id: $id) {
            awaiting,
            active,
            invited,
            rejected,
            blocked
          }
        }
      `
    })
  }
}


import { MutationTree } from 'vuex'
import { SystemState } from './types'
import { CLEAR_RETURN_LOCATION, SET_META, SET_RETURN_MODEL } from '../../actions/system'

export const mutations: MutationTree<SystemState> = {
  [SET_RETURN_MODEL] (state, payload) {
    state.returnCrudModel = payload.crudModel
    state.returnLocation = payload.from
    state.returnLabel = payload.returnLabel
    state.completeLabel = payload.completeLabel
    state.attachModelId = payload.attachModelId
  },
  [CLEAR_RETURN_LOCATION] (state) {
    state.returnLocation = ''
    state.returnLabel  = ''
    state.completeLabel = ''
    state.returnCrudModel = void 0
    state.attachModelId = void 0
  },
  [SET_META] (state, payload) {
    state.meta = {
      ...state.meta,
      ...payload
    }
  }
}
